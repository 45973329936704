import React from "react";
import AddTeamMemberMain from "components/Teams/AddTeamMemberMain";

const AddTeamMember: React.FC = () => {
  return (
    <>
      <AddTeamMemberMain />
    </>
  );
};

export default AddTeamMember;
