import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "pages/LoginPage";
import { withLoading } from "hocs/withLoading.hoc";
import RequireAuth from "./RequireAuth";
import MainLayout from "layouts/MainLayout/MainLayout";
import { Register } from "components/Register/Register";
import { VerifyEmail } from "components/VerifyEmail/VerifyEmail";
import AddTeamMember from "pages/Teams/AddTeamMember";
import BussinessDashboard from "components/Dashboard/BussinessDashboard";

export const DASHBOARD_PATH = "/";

const AuthLayoutFallback = React.lazy(() => import("layouts/AuthLayout/AuthLayout"));
const DashboardPage = React.lazy(() => import("pages/DashboardPage"));
const RecordListPage = React.lazy(() => import("pages/RecordListPage"));
const AddRecordPage = React.lazy(() => import("pages/AddRecordPage"));
const VericationPage = React.lazy(() => import("pages/VerificationPage"));
const BusinessVerificationPage = React.lazy(() => import("pages/BusinessVerificationPage"));

const RechargePage = React.lazy(() => import("pages/RechargePage"));
const OrderListPage = React.lazy(() => import("pages/OrderListPage"));
const TeamsPage = React.lazy(() => import("pages/Teams/TeamsPage"));

const Logout = React.lazy(() => import("./Logout"));
const Dashboard = withLoading(DashboardPage);
const RecordList = withLoading(RecordListPage);
const AddRecord = withLoading(AddRecordPage);
const Recharge = withLoading(RechargePage);
const OrderList = withLoading(OrderListPage);
const Teams = withLoading(TeamsPage);
const AddTeams = withLoading(AddTeamMember);
const LogoutFallback = withLoading(Logout);
const Verification = withLoading(VericationPage);
const BusinessVerification = withLoading(BusinessVerificationPage);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="record-list/:id" element={<RecordList />} />
          <Route path="add-identity-verification" element={<Verification />} />
          <Route path="add-business-verification" element={<BusinessVerification />} />
          <Route path="add-record" element={<AddRecord />} />
          <Route path="recharge" element={<Recharge />} />
          <Route path="order-list" element={<OrderList />} />
          <Route path="teams" element={<Teams />} />
          <Route path="add-teams" element={<AddTeams />} />
          <Route path="bussiness-dashboard" element={<BussinessDashboard />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<Register />} />
          <Route path="verify" element={<VerifyEmail />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
