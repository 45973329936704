/* eslint-disable max-len */
/* eslint-disable newline-per-chained-call */
import React, { useEffect } from "react";

import { Dropdown, Layout, Menu, MenuProps, Space, theme } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";

import { getCreditInfo } from "store/slices/auth.slice";

const { Header, Content } = Layout;

const MainLayout: React.FC = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.userDetails);
  const availableCredit = useAppSelector((state) => state.auth.availableCredit);
  const dispatch = useAppDispatch();

  const {
    token: { borderRadiusLG, colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    dispatch(getCreditInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items: MenuProps["items"] = [
    // {
    //   key: "1",
    //   label: (
    //     <a  onClick={()=>{
    //       navigate("/profile");
    //     }}>
    //       Profile
    //     </a>
    //   ),
    // },
    {
      key: "2",
      label: (
        <a
          onClick={() => {
            navigate("/logout");
          }}
        >
          Logout
        </a>
      ),
      // icon: <SmileOutlined />,
      // disabled: true,
    },
  ];

  return (
    <Layout>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 999,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="demo-logo">
          <a href="/">
            <img src="Logo-white.svg" alt="" />
          </a>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          {" "}
          <Menu
            style={{ width: "700px", alignItems: "center", height: "100%" }}
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["dashboard"]}
            items={[
              {
                key: "dashboard",
                label: "PAN List",
                onClick: () => {
                  navigate("/dashboard");
                },
              },

              // {
              //   key: "add-bussiness-verification",
              //   label: "Bussiness Verification",
              //   onClick: () => {
              //     navigate("/bussiness-dashboard");
              //   },
              // },
              // {
              //   key: "teams",
              //   label: "Teams",
              //   onClick: () => {
              //     navigate("/teams");
              //   },
              // },
              {
                key: "add-record",
                label: "Get PAN Details ",
                onClick: () => {
                  navigate("/add-record");
                },
              },
              {
                key: "add-verification",
                label: "Identity Verification",
                onClick: () => {
                  navigate("/add-identity-verification");
                },
              },
              {
                key: "add-business-verification",
                label: "Business Verification",
                onClick: () => {
                  navigate("/add-business-verification");
                },
              },
              {
                key: "recharge",
                label: "Recharge",
                onClick: () => {
                  navigate("/order-list");
                },
              },
            ]}
          />
          <div style={{ display: "flex" }}>
            <div>
              <span
                style={{
                  borderRadius: "5px",
                  padding: "5px 10px",
                  marginRight: "10px",
                  fontSize: "20px",
                  display: "inline",
                  background: "#fff",
                }}
              >
                Balance Credit : {availableCredit}
              </span>
            </div>
            <Dropdown menu={{ items }}>
              <a onClick={(e) => e.preventDefault()} style={{ color: "#fff" }}>
                <Space>
                  Welcome {user?.firstName + " " + user?.lastName}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
      </Header>
      <Content style={{ padding: "0 0" }}>
        <div
          style={{
            padding: "0px 0px",
            minHeight: 380,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Outlet />
        </div>
      </Content>
      {/* <Footer style={{ textAlign: "center" }}>Verifyme.ai {new Date().getFullYear()}</Footer> */}
    </Layout>
  );
};

export default MainLayout;
