import { httpApi } from "./http.api";

export interface UserFormValues {
  user: {
    email: string;
    password: string;
    title: string;
    firstName: string;
    lastName: string;
    role: string;
    parentId: number;
  };
}

export interface CreateUserResponse {
  message: string;
}

export interface TeamsMemberListResponse {
  message: string;
  data: IOTeamsList[];
}

export interface IOTeamsList {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  parentId: number;
  role: string;
}

export interface IdInterface {
    id: number
}

export const getTeamMembersList = (): Promise<IOTeamsList> =>
  httpApi.get<IOTeamsList>("team").then(({ data }) => data);

export const getTeamMember = (memberId: string): Promise<UserFormValues> =>
  httpApi.get<UserFormValues>(`team/${memberId}`).then(({ data }) => data);

export const CreateTeamMember = (TeamMemberData: UserFormValues): Promise<CreateUserResponse> =>
  httpApi.post<CreateUserResponse>("team", { ...TeamMemberData }).then(({ data }) => data);

export const UpdateTeamMember = (TeamMemberData: UserFormValues, id: number): Promise<CreateUserResponse> =>
  httpApi.put<CreateUserResponse>(`team/${id}`, { ...TeamMemberData }).then(({ data }) => data);

export const deleteTeamMember = ( id: number): Promise<TeamsMemberListResponse> =>
  httpApi.delete<TeamsMemberListResponse>(`team/${id}`).then(({ data }) => data);