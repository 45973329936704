/* eslint-disable max-len */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Card, Col, Form, Input, Typography, Row, Space } from "antd";
import { notificationController } from "components/Common/Notification/Notification";


import { SignUpRequest, signUp } from "api/auth.api";
import { passwordRegex } from "utils";

export const Register: React.FC = () => {
  const navigate = useNavigate();


  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: SignUpRequest) => {
    setLoading(true);
    signUp({...values, title:"Mr",acceptTerms:true}).then((data)=>{
      notificationController.success({message:data.message});
      setLoading(false);
      navigate("/auth/login");
    },(error)=>{
      setLoading(false);
      notificationController.success({message:error.message});
    });
    
  };

  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card bordered={true} style={{ width: 400 }} className="shadow">
          <Row justify={"center"}>
            <Col>
              <img
                className="login-card"
                src="/Logo.svg"
                alt=""
              />
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col>
              <Typography.Title level={3}>Register</Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                autoComplete="off"
              >
                

                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      pattern: passwordRegex,
                      message: "Minimum 8 characters, at least one letter and one number",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("The two passwords that you entered do not match!"));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  label="Mobile Number"
                  name="mobileNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input your mobile number!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    Submit
                  </Button>
                  <Space>
                    <Button type="link" onClick={()=>{
                      navigate("/auth/login");
                    }}   loading={isLoading}>
                   Already Have Account?
                    </Button></Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
