/* eslint-disable no-useless-catch */
import axios from "axios";

const fetchTanData = async () => {
  try {
    const url = "https://dev-hh9axcdqtqeth48.api.raw-labs.com/your/endpoint/PAN";
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    //console.log("got an error");
    throw error;
  }
};

export default fetchTanData;
