import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Form, Input, Select, Row, Col, Typography } from "antd";
import { CreateTeamMember, getTeamMember, UpdateTeamMember, UserFormValues } from "api/teams.api";
import { notificationController } from "components/Common/Notification/Notification";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { passwordRegex } from "utils";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const AddTeamMemberMain: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [teamsMember, setTeamsMember] = useState<UserFormValues | null>(null);

  const memberId = searchParams.get("memberId");
  const role = "TeamMember";
  const hardcodedParentId = 12345;

  const handleSubmit = (values: UserFormValues) => {
    setIsLoading(true);

    const updatedValues = { ...values, parentId: hardcodedParentId, role };

    if (memberId) {
      UpdateTeamMember(updatedValues, parseInt(memberId))
        .then(() => {
          notificationController.success({ message: "Team Member Updated!" });
          setIsLoading(false);
          navigate("/teams");
        })
        .catch((error) => {
          notificationController.error({ message: error.message });
          setIsLoading(false);
        });
    } else {
      CreateTeamMember(updatedValues)
        .then(() => {
          notificationController.success({
            message:
              "Team member added successfully! A verification request has been sent to the team member.",
          });
          setIsLoading(false);
          navigate("/teams");
        })
        .catch((error) => {
          notificationController.error({ message: error.message });
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (memberId) {
      getTeamMember(memberId)
        .then((response: UserFormValues) => {
          setTeamsMember(response);
          form.setFieldsValue(response);
        })
        .catch((error) => {
          notificationController.error({ message: error.message });
        });
    }
  }, [memberId, form]);

  return (
    <div style={{ width: "100%", minHeight: "75vh" }}>
      <Row justify={"space-between"} align={"middle"} style={{ marginBottom: "12px" }}>
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={() => navigate("/teams")}
              style={{ marginRight: "10px" }}
              icon={<ArrowLeftOutlined />}
            />
            <Typography.Title style={{ marginTop: "0", marginBottom: "0" }} level={3}>
              {memberId ? "Update Team Members" : "Add Team Member"}
            </Typography.Title>
          </div>
        </Col>
        <Col></Col>
      </Row>

      <hr style={{ borderColor: "rgb(204 204 204 / 16%)" }} />

      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        initialValues={teamsMember ?? {}}
      >
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item name="title" label="Title" rules={[{ required: true }]}>
              <Select placeholder="Select a title">
                <Select.Option value="Mr.">Mr.</Select.Option>
                <Select.Option value="Mrs.">Mrs.</Select.Option>
                <Select.Option value="Miss">Miss</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="firstName" label="First Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="lastName" label="Last Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="email" label="Email" rules={[{ type: "email", required: true }]}>
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true },
                {
                  pattern: passwordRegex,
                  message: "Minimum 8 characters, at least one letter and one number",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["password"]}
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Passwords do not match!"));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {memberId ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddTeamMemberMain;
