/* eslint-disable max-len */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Card, Col, Form, Input,  Typography, Row } from "antd";

import { notificationController } from "components/Common/Notification/Notification";
import {  VerifyEmailRequest, verifyEmail } from "api/auth.api";


export const VerifyEmail: React.FC = () => {
  const navigate = useNavigate();


  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: VerifyEmailRequest) => {
    setLoading(true);
    verifyEmail(values.token).then(()=>{
      setLoading(false);
      notificationController.success({ message: "Your email verified successfully." });
      navigate("/");
    },(err) => {
      notificationController.error({ message: err.message });
      setLoading(false);
    });
   
  };

  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card bordered={true} style={{ width: 400 }} className="shadow">
          <Row justify={"center"}>
            <Col>
          
              <img
                className="login-card"
                src="/Logo.svg"
                alt=""
              />{" "}
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col>
              <Typography.Title level={3}>Verify Email</Typography.Title>{" "}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                autoComplete="off"
              >
                <Form.Item
                  label="Verification Code"
                
                  name="token"
                  rules={[
                    {
                      required: true,
                      message: "Please input your token!",
                    },
                  ]}
                >
                  <Input placeholder="Please input your token!" />
                </Form.Item>

             
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    Submit
                  </Button>
                  <Button  type="link" onClick={()=>{
                    navigate("/auth/login");
                  }}> Login</Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
