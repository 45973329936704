import React, { useState } from "react";
import { Checkbox, Layout, Menu, Input, Button, Alert, Modal } from "antd";
import type { CheckboxProps } from "antd";
import { IdcardOutlined, CreditCardOutlined, BankOutlined } from "@ant-design/icons";
import fetchTanData from "api/bussiness _verification/fatchTanData";
// import ResultDisplay from "../ResultDisplay/ResultDisplay";

const { Header, Content, Sider } = Layout;

type MenuItem = {
  key: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
  label: React.ReactNode;
};

const items: MenuItem[] = [
  { key: "1", icon: <CreditCardOutlined />, label: "TAN" },
  { key: "2", icon: <BankOutlined />, label: "GST " },
  { key: "3", icon: <IdcardOutlined />, label: "UAN" },
];

const validationRules: Record<
  string,
  { placeholder: string; regex: RegExp; warningMessage: string }
> = {
  "1": {
    placeholder: "Enter TAN Number e.g. ABCD12345E",
    regex: /^[A-Z]{4}[0-9]{5}[A-Z]$/,
    warningMessage: "Please enter a valid TAN number. e.g., ABCD12345E",
  },
  "2": {
    placeholder: "Enter GST Number e.g. 22ABCDE1234F1Z5",
    regex: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{3}$/,
    warningMessage: "Please enter a valid GST number. e.g., 22ABCDE1234F1Z5",
  },
  "3": {
    placeholder: "Enter Udyam Adhar Number e.g. 1234567890123456789",
    regex: /^\d{19}$/,
    warningMessage: "Please enter a valid Udyam Adhar number. It should be exactly 19 digits.",
  },
};

const BussinessDashboard: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState<keyof typeof validationRules>("1");
  const [selectedLabel, setSelectedLabel] = useState("PAN");
  const [inputValue, setInputValue] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [submittedData, setSubmittedData] = useState<null | {
    key: string;
    value: string;
    details: undefined;
  }>(null);
  const [isAlreadySubmitted, setIsAlreadySubmitted] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [showInput, setShowInput] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    const validation = validationRules[selectedKey];
    const isValidInput = validation.regex.test(value);

    if (selectedKey === "2") {
      setIsValid(value.length === 12 && isValidInput);
    } else {
      setIsValid(isValidInput);
    }

    setShowWarning(!isValidInput && value.length > 0);
  };

  const handleMenuClick = (e: { key: React.Key }) => {
    const selectedMenuItem = items.find((item) => item.key === e.key);
    if (selectedMenuItem) {
      setSelectedKey(e.key as keyof typeof validationRules);
      setSelectedLabel(selectedMenuItem.label as string);
      setInputValue("");
      setIsValid(false);
      setShowWarning(false);
      setIsAlreadySubmitted(false);
      setSubmittedData(null);
      setShowInput(true);
    }
  };

  const handleSubmit = async () => {
    if (isAlreadySubmitted) {
      setModalVisible(true);
    } else if (isValid) {
      setLoading(true);
      try {
        let details: undefined;
        switch (selectedKey) {
          case "1":
            details = await fetchTanData();
            break;

          default:
            details = undefined;
        }
        if (details) {
          setSubmittedData({
            key: selectedKey,
            value: inputValue,
            details,
          });
          setIsAlreadySubmitted(true);
          setShowInput(false);
        }
      } catch (error) {
        alert("error");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const onChange: CheckboxProps["onChange"] = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={false}>
        <div className="logo" />
        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline" onClick={handleMenuClick}>
          {items.map((item) => (
            <Menu.Item key={item.key} icon={item.icon}>
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header style={{ padding: 0, background: "transparent" }}>
          <h1 style={{ padding: "20px", fontSize: "1.2rem" }}>{selectedLabel} Verification</h1>
        </Header>
        <Content style={{ margin: "10px 16px" }}>
          <div style={{ padding: 24, minHeight: 360, background: "#fff", borderRadius: "6px" }}>
            {showInput && (
              <>
                <Input
                  placeholder={validationRules[selectedKey].placeholder}
                  value={inputValue}
                  onChange={handleInputChange}
                  style={{
                    marginBottom: 16,
                    width: 500,
                    display: "block",
                    padding: 12,
                    animation: "fadeIn 1s ease-in-out",
                    borderRadius: 6,
                    fontSize: "16px",
                  }}
                />
                {showWarning && (
                  <Alert
                    message={validationRules[selectedKey].warningMessage}
                    type="warning"
                    showIcon
                    style={{ marginBottom: "13px" }}
                  />
                )}

                <div
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  <Checkbox onChange={onChange}>I agree to the Terms and Conditions</Checkbox>
                </div>
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: "#1890ff",
                    border: "none",
                    height: "42px",
                    width: "auto",
                    color: "white",
                    padding: "11x 30px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    fontSize: "16px",
                    animation: "fadeIn 1s ease-in-out",
                    opacity: !isValid || !isChecked || isAlreadySubmitted ? 0.5 : 1,
                  }}
                  disabled={!isValid || inputValue.length === 0 || !isChecked}
                  loading={loading}
                >
                  Verify {selectedLabel}
                </Button>
              </>
            )}
            {submittedData && !showInput && (
              <>
                {/* <ResultDisplay
                  details={submittedData.details}
                  selectedKey={selectedKey}
                  selectedLabel={selectedLabel}
                /> */}
                <Modal
                  title={`${selectedLabel} Verification`}
                  visible={modalVisible}
                  onCancel={handleModalClose}
                  footer={null}
                >
                  You have already submitted the {selectedLabel} verification request.
                </Modal>
              </>
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default BussinessDashboard;
